import { Body, type DeeplyNestedSx, Icon, Link } from "@biom3/react";
import { useTranslation } from "react-i18next";
import { merge } from "ts-deepmerge";

export const LegalLinks = ({
  containerSx = {},
}: {
  containerSx?: DeeplyNestedSx;
}) => {
  const { t } = useTranslation();

  return (
    <Body
      size="xSmall"
      sx={merge(
        {
          color: "base.color.text.body.secondary",
          lineHeight: "base.text.body.small.regular.lineHeight",
          display: "flex",
          gap: "base.spacing.x1",
        },
        containerSx,
      )}
    >
      <Link
        size="xSmall"
        variant="secondary"
        rc={
          <a
            href="https://www.immutable.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          />
        }
      >
        {t("terms_of_service")}
      </Link>
      <Icon icon="JumpTo" />
    </Body>
  );
};
