import { MODAL_HEIGHT, MODAL_WIDTH } from "@/constants";
import { ButtCon, Modal } from "@biom3/react";
import { useCallback } from "react";

interface RewardBaseModalProps {
  visible: boolean | undefined;
  close?: () => void;
  children?: React.ReactNode;
  backgroundImg?: string;
  withPadding?: boolean;
  closeVariant?: "tertiary" | "tertiary/inverse";
}

const RewardBaseModal = ({
  visible,
  children,
  withPadding = true,
  backgroundImg,
  closeVariant = "tertiary",
  close,
}: RewardBaseModalProps) => {
  const modalStyle = {
    d: "flex",
    flexDirection: "column",
    borderRadius: "base.borderRadius.x10",
    maxw: MODAL_WIDTH,
    w: "100vw",
    height: "auto",
    minh: MODAL_HEIGHT,
    overflow: "hidden",
    background: "#2b2829",
    ...(withPadding && {
      px: "base.spacing.x6",
      pb: "base.spacing.x8",
      pt: "base.spacing.x20",
    }),
    ...(backgroundImg && {
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
    }),
    gap: "base.spacing.x4",
  };

  const handleClose = useCallback(() => close?.(), [close]);

  return (
    <Modal visible={visible} onCloseModal={close}>
      <Modal.Content sx={modalStyle}>
        {children}
        {close !== null && (
          <ButtCon
            icon="Close"
            variant={closeVariant}
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "base.spacing.x4",
              top: "base.spacing.x4",
            }}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
export { RewardBaseModal };
