import {
  IMMUTABLE_GAME_ID,
  IMMUTABLE_GAME_NAME,
  SOCIAL_CONNECTIONS,
  type SocialConnection,
} from "@/constants";
import { useAccessTokenState } from "@/hooks";
import {
  linkedAccountPlatforms,
  openAuthorizeUrl,
} from "@/utils/linkedaccounts/linkedAccounts";
import { authorizedGet } from "@/utils/request";
import { analytics } from "@analytics";
import { Box, ButtCon, Heading, MenuItem, Stack } from "@biom3/react";
import type { QuestKey, QuestState } from "@ui-kit/QuestList";
import { motion } from "motion/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { FollowConfirmationModal } from "../FollowConfirmationModal";

const PLATFORM_KEYS: Record<string, string> = {
  "x-v2": "x",
  "discord-v2": "discord",
};

type LinkedPlatform = (typeof PLATFORM_KEYS)[keyof typeof PLATFORM_KEYS];

interface LinkedAccount {
  platform: LinkedPlatform;
  external_id: string;
  created_at: string;
  updated_at: string;
  username: string;
}

interface ConnectionsOverlayProps {
  onBack: () => void;
}

const isConnectionLinked = (
  connection: SocialConnection,
  linkedAccounts?: LinkedAccount[],
): boolean => {
  if (!linkedAccounts) return false;

  return linkedAccounts.some(
    (account) => account.platform === PLATFORM_KEYS[connection.key],
  );
};

const getPlatformForConnection = (connection: SocialConnection) => {
  const platform = linkedAccountPlatforms.get(connection.key);
  if (!platform) {
    console.error(`Platform not found for connection key: ${connection.key}`);
  }
  return platform;
};

const getAuthorizeUrlForConnection = (
  connection: SocialConnection,
): string | null => {
  const platform = getPlatformForConnection(connection);
  if (!platform) return null;

  return platform.getAuthorizeUrl();
};

const createQuestForConnection = (connection: SocialConnection): QuestState => {
  const eventId = `link-${connection.platform.toLowerCase()}-account`;

  return {
    ruleId: eventId,
    key: connection.key as Exclude<QuestKey, "ownership">,
    label: `Connect ${connection.platform}`,
    caption: `Connect your ${connection.platform} account`,
    type: "link",
    icon: connection.platform,
    increase: 0,
    badgeIcon: "ImxRewards",
    badgeTemplate: "Gems",
    eventId,
    status: "pending",
    completed: false,
    isChest: false,
    locked: false,
    hidden: false,
    skippable: false,
  };
};

const openConnectionAuthorizeUrl = (
  connection: SocialConnection,
  authorizeUrl: string,
) => {
  const eventId = `link-${connection.platform.toLowerCase()}-account`;

  openAuthorizeUrl(
    eventId,
    connection.key,
    authorizeUrl,
    IMMUTABLE_GAME_NAME,
    IMMUTABLE_GAME_ID,
    true,
  );
};

interface Connection extends SocialConnection {
  quest: QuestState;
  linkedAccount?: LinkedAccount;
}

export function ConnectionsOverlay({ onBack }: ConnectionsOverlayProps) {
  const accessToken = useAccessTokenState();
  const [activeConnection, setActiveConnection] = useState<Connection | null>(
    null,
  );
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: linkedAccounts, mutate } = useSWR<{
    linked_accounts: LinkedAccount[];
  }>(
    accessToken ? ["/passport-profile/v1/linked-accounts", accessToken] : null,
    async ([path, token]: [string, string]) => {
      return authorizedGet(path, token);
    },
    {
      revalidateOnFocus: true,
      refreshInterval: activeConnection ? 3000 : 0,
    },
  );

  const connections = useMemo(
    () =>
      SOCIAL_CONNECTIONS.map((connection) => ({
        ...connection,
        quest: createQuestForConnection(connection),
        linkedAccount: linkedAccounts?.linked_accounts.find(
          (account) => account.platform === PLATFORM_KEYS[connection.key],
        ),
      })),
    [linkedAccounts],
  );

  const handleConnect = (connection: Connection) => {
    analytics.track("Play_Button_Pressed", {
      controlLabel: connection.platform,
      section: "ConnectionsOverlay",
    });

    const authorizeUrl = getAuthorizeUrlForConnection(connection);
    if (!authorizeUrl) return;

    setActiveConnection(connection);
    setShowConnectionModal(true);
    setError(null);

    openConnectionAuthorizeUrl(connection, authorizeUrl);
  };

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.key === "social_auth_error" &&
        event.newValue &&
        showConnectionModal
      ) {
        setError(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [showConnectionModal]);

  // Check if the connection is confirmed when linkedAccounts changes
  useEffect(() => {
    if (
      showConnectionModal &&
      activeConnection &&
      linkedAccounts?.linked_accounts
    ) {
      if (
        isConnectionLinked(activeConnection, linkedAccounts.linked_accounts)
      ) {
        setShowConnectionModal(false);
      }
    }
  }, [linkedAccounts, activeConnection, showConnectionModal]);

  const handleCloseModal = useCallback(() => {
    setShowConnectionModal(false);
    setActiveConnection(null);
    setError(null);
    localStorage.removeItem("social_auth_error");
    mutate();
  }, [mutate]);

  const handleConfirm = useCallback(async () => {
    if (activeConnection && linkedAccounts?.linked_accounts) {
      if (
        isConnectionLinked(activeConnection, linkedAccounts.linked_accounts)
      ) {
        await mutate();
        return;
      }
    }

    await mutate();
  }, [linkedAccounts, mutate, activeConnection]);

  const handleRetry = useCallback(() => {
    if (activeConnection) {
      setError(null);
      localStorage.removeItem("social_auth_error");

      const authorizeUrl = getAuthorizeUrlForConnection(activeConnection);
      if (!authorizeUrl) return;

      window.open(authorizeUrl, "_blank");
    }
  }, [activeConnection]);

  const handleQuestClick = useCallback(() => {
    if (activeConnection) {
      const authorizeUrl = getAuthorizeUrlForConnection(activeConnection);
      if (!authorizeUrl) return;

      openConnectionAuthorizeUrl(activeConnection, authorizeUrl);
    }
  }, [activeConnection]);

  return (
    <Box
      rc={
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", bounce: 0, duration: 0.4 }}
        />
      }
      sx={{ height: "100%", bg: "base.color.fixed.black.1000" }}
    >
      <Box
        sx={{
          position: "relative",
          padding: "base.spacing.x2",
        }}
      >
        <ButtCon
          icon="ChevronBackward"
          variant="tertiary"
          iconVariant="bold"
          sx={{
            pos: "absolute",
            top: "base.spacing.x5",
            left: "base.spacing.x5",
            zIndex: 1,
          }}
          onClick={onBack}
        />
        <Box
          sx={{
            w: "100%",
            borderRadius: "base.spacing.x2",
            position: "relative",
            bg: "base.color.translucent.inverse.800",
            pt: "base.spacing.x12",
            px: "base.spacing.x6",
            pb: "base.spacing.x6",
          }}
        >
          <Heading
            size="small"
            weight="bold"
            sx={{
              my: "base.spacing.x8",
            }}
          >
            Social Connections
          </Heading>

          <Stack sx={{ gap: "base.spacing.x4" }}>
            {connections.map((connection) => {
              return (
                <MenuItem
                  sx={{
                    bg: "transparent",
                  }}
                  key={connection.platform}
                  emphasized
                  onClick={() =>
                    !connection?.linkedAccount && handleConnect(connection)
                  }
                  disabled={!!connection?.linkedAccount}
                  size="small"
                >
                  <MenuItem.FramedIcon icon={connection.platform} />
                  <MenuItem.Label>
                    {connection.platform}
                    {connection.platform === "X" ? " (Formerly Twitter)" : null}
                  </MenuItem.Label>
                  {connection?.linkedAccount ? null : activeConnection?.key ===
                    connection.key ? (
                    <MenuItem.IntentIcon
                      key="Loading"
                      icon="Loading"
                      sx={{
                        fill: "base.color.text.body.secondary",
                      }}
                    />
                  ) : (
                    <MenuItem.IntentIcon
                      key="ChevronForward"
                      icon="ChevronForward"
                      variant="bold"
                      sx={{
                        fill: "base.color.text.body.secondary",
                      }}
                    />
                  )}

                  {connection?.linkedAccount && (
                    <MenuItem.Caption
                      sx={{
                        color: "base.color.text.status.success.primary",
                      }}
                    >
                      Connected to @{connection?.linkedAccount?.username}
                    </MenuItem.Caption>
                  )}
                </MenuItem>
              );
            })}
          </Stack>
        </Box>
      </Box>

      {activeConnection && (
        <FollowConfirmationModal
          visible={showConnectionModal}
          onConfirm={handleConfirm}
          onRetry={handleRetry}
          onQuestClick={handleQuestClick}
          onClose={handleCloseModal}
          quest={activeConnection.quest}
          gameName={IMMUTABLE_GAME_NAME}
          error={error}
          setError={setError}
        />
      )}
    </Box>
  );
}
