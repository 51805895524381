import { appConfig } from "@/constants";
import { notifyError } from "@/utils/monitoring";
import { authorizedPost } from "../request";
import type { LinkedAccountPlatform } from "./linkedAccounts";

const LOGIN_PAGE_NAME = "/discord-login";
const DISCORD_CLIENT_ID = "1329309372249149520";
const DISCORD_ACCESS_TOKEN_LOCAL_STORAGE_KEY = "discord_access_token";
const DISCORD_REFRESH_TOKEN_LOCAL_STORAGE_KEY = "discord_refresh_token";
const DISCORD_USER_ID_LOCAL_STORAGE_KEY = "discord_user_id";

export const DiscordLinkedAccountPlatform: LinkedAccountPlatform = {
  imageUrl: "/images/quests/discord.svg",
  name: "Discord",
  questAction: "accept the invite",
  getAuthorizeUrl: () => {
    // Temporary: Clear any existing tokens until we add support for refreshing and handling errors
    localStorage.removeItem(DISCORD_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(DISCORD_REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(DISCORD_USER_ID_LOCAL_STORAGE_KEY);

    const redirectUri = `${appConfig.BASE_REDIRECT_URL}${LOGIN_PAGE_NAME}`;
    return `https://discord.com/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=identify+guilds`;
  },
  login: async (accessToken: string, code: string) => {
    const redirectUrl = `${appConfig.BASE_REDIRECT_URL}${LOGIN_PAGE_NAME}`;
    const response = await authorizedPost(
      "/passport-profile/v1/discord-auth",
      { code, redirect_url: redirectUrl },
      accessToken,
    );

    const { access_token, refresh_token, external_user_id } = response;
    if (!access_token || !refresh_token || !external_user_id) {
      throw new Error("Invalid response format from Discord authentication.");
    }

    localStorage.setItem(DISCORD_ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);
    localStorage.setItem(
      DISCORD_REFRESH_TOKEN_LOCAL_STORAGE_KEY,
      refresh_token,
    );
    localStorage.setItem(DISCORD_USER_ID_LOCAL_STORAGE_KEY, external_user_id);

    return response;
  },
  validateQuest: async (
    gameId: string,
    questId: string,
    accessToken: string,
  ) => {
    try {
      return await authorizedPost(
        "/passport-profile/v1/discord-quest",
        {
          discord_access_token: localStorage.getItem(
            DISCORD_ACCESS_TOKEN_LOCAL_STORAGE_KEY,
          ),
          quest_id: questId,
          game_id: gameId,
        },
        accessToken,
      );
    } catch (error) {
      notifyError(error, "discordQuest");
      throw error;
    }
  },
  isLoggedIn: () => {
    return !!localStorage.getItem(DISCORD_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  },
};
