import { Body, Button, CloudImage, Heading, Video } from "@biom3/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MODAL_HEIGHT, MODAL_WIDTH, appConfig } from "@/constants";

import { RewardBaseModal } from "../RewardBaseModal/RewardBaseModal";

interface ClaimChestModalProps {
  opened: boolean;
  close: () => void;
}

const ClaimChestModal = ({ opened, close }: ClaimChestModalProps) => {
  const { t } = useTranslation();
  const [animModalOpened, setAnimModalOpened] = useState(opened);
  const [finalOpened, setFinalOpened] = useState(false);
  useEffect(() => {
    setAnimModalOpened(opened);
  }, [opened]);
  const handleAnimationEnded = () => {
    setAnimModalOpened(false);
    setFinalOpened(true);
  };
  const handleClose = () => {
    if (finalOpened) {
      setFinalOpened(false);
    }
    if (close) {
      close();
    }
  };
  return (
    <>
      <RewardBaseModal visible={animModalOpened} withPadding={false}>
        <Video
          sx={{
            objectFit: "cover",
            width: MODAL_WIDTH,
            height: MODAL_HEIGHT,
          }}
          mimeType="video/mp4"
          videoUrl={`${appConfig.PLAY_ASSETS_CDN_BASE_URL}/videos/immutable/claimChestModal.mp4`}
          autoPlay
          muted={false}
          onEnded={() => {
            handleAnimationEnded();
          }}
        />
      </RewardBaseModal>
      <RewardBaseModal visible={finalOpened} close={handleClose}>
        <CloudImage
          imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/50-gems-card.png`}
          relativeImageSizeInLayout="100px"
          sx={{
            alignSelf: "center",
            width: "160px",
          }}
        />
        <Heading
          size="medium"
          sx={{
            px: "base.spacing.x3",
            textAlign: "center",
          }}
        >
          {t("rewards_chest_gems")}
        </Heading>
        <Body
          size="small"
          sx={{
            color: "base.color.text.body.secondary",
            textAlign: "center",
          }}
        >
          {t("coming_soon")}
        </Body>
        <Button
          size="large"
          variant="primary"
          onClick={handleClose}
          sx={{ mt: "auto" }}
        >
          {t("done")}
        </Button>
      </RewardBaseModal>
    </>
  );
};
export { ClaimChestModal };
