import { BaseClickable, Body, Box, Heading } from "@biom3/react";
import Image from "next/image";
import NextLink from "next/link";
import { Trans, useTranslation } from "react-i18next";

import { CopyWalletAddress } from "@/components";
import { Modal } from "@/components/modal";
import { pageLegacyEvent } from "@analytics";
import { useEffect } from "react";

type CopyWalletModalProps = {
  isCopyWalletModalVisible: boolean;
  setCopyWalletModalVisible: (isCopyWalletModalVisible: boolean) => void;
};

const clickableTextStyle = {
  color: "base.color.accent.1",
  fontSize: "base.text.body.small.regular.fontSize",
};

export const CopyWalletModal = ({
  isCopyWalletModalVisible,
  setCopyWalletModalVisible,
}: CopyWalletModalProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isCopyWalletModalVisible) {
      pageLegacyEvent({
        userJourney: "Wallets",
        screen: "CopyWalletAddress",
      });
    }
  }, [isCopyWalletModalVisible]);

  return (
    <Modal
      open={isCopyWalletModalVisible}
      rightButtCon="Close"
      onCloseModal={() => setCopyWalletModalVisible(false)}
      divider={false}
    >
      <Modal.Content>
        <Box sx={{ height: "250px", width: "300px", mx: "auto" }}>
          <Image
            src="/images/emptyWalletState.svg"
            alt="Empty Wallet State"
            width={429}
            height={305}
            objectFit="contain"
            layout="responsive"
          />
        </Box>
        <Box
          sx={{
            d: "flex",
            flexDirection: "column",
            background: "base.color.brand.2",
            p: "base.spacing.x7",
          }}
        >
          <Heading size="small">{t("sending_assets_to_passport")}</Heading>
          <Body
            sx={{
              mt: "base.spacing.x2",
              mb: "base.spacing.x4",
              fontSize: "base.text.body.small.regular.fontSize",
            }}
          >
            <ul style={{ paddingLeft: "22px" }}>
              <li>
                <Trans
                  i18nKey={"only_transfer_on_immutable_x_or_immutable_zkevm"}
                  components={{
                    emphasised: (
                      <Body
                        sx={{
                          fontWeight: "base.text.body.large.bold.fontWeight",
                        }}
                      />
                    ),
                    wallet: (
                      <BaseClickable
                        sx={clickableTextStyle}
                        rc={<NextLink href={{ pathname: "/balance" }} />}
                      />
                    ),
                    tokenTrove: (
                      <BaseClickable
                        sx={clickableTextStyle}
                        rc={
                          <NextLink
                            href={{ pathname: "https://tokentrove.com" }}
                            target="_blank"
                          />
                        }
                      />
                    ),
                  }}
                />
              </li>
              <li>{t("dont_transfer_on_ethereum_mainnet")}</li>
            </ul>
          </Body>
          <CopyWalletAddress size="xSmall" />
        </Box>
      </Modal.Content>
    </Modal>
  );
};
