import { Toast } from "@biom3/react";
import {
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

export type NotificationType = "success" | "fatal" | "guidance" | "attention";

interface NotificationItem {
  id: number;
  visible: boolean;
  message: ReactNode;
  variant: NotificationType;
  duration?: number;
  action?: {
    label: string;
    onClick: () => void;
  };
}

interface NotificationContextType {
  showNotification: (
    message: ReactNode,
    options?: Omit<NotificationItem, "visible" | "message" | "id">,
  ) => number;
  hideNotification: (id: number) => void;
}

const NotificationContext = createContext<NotificationContextType | null>(null);

export function NotificationProvider({ children }: PropsWithChildren) {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const nextId = useRef(0);

  const hideNotification = (id: number) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id
          ? { ...notification, visible: false }
          : notification,
      ),
    );
  };

  const showNotification = (
    message: ReactNode,
    options?: Omit<NotificationItem, "visible" | "message" | "id">,
  ) => {
    const id = nextId.current;
    nextId.current++;
    const newNotification: NotificationItem = {
      id,
      visible: true,
      message,
      variant: options?.variant || "guidance",
      duration: options?.duration,
      action: options?.action,
    };

    setNotifications((prev) => [...prev, newNotification]);
    return id;
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        hideNotification,
      }}
    >
      {children}
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          visible={notification.visible}
          onCloseToast={() => hideNotification(notification.id)}
          variant={notification.variant}
          autoDismissDuration={notification.duration}
        >
          <Toast.Message>{notification.message}</Toast.Message>
          {notification.action && (
            <Toast.Button onClick={notification.action.onClick}>
              {notification.action.label}
            </Toast.Button>
          )}
        </Toast>
      ))}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
}
