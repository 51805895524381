import type { Quest, QuestKey } from "@ui-kit/QuestList";
import { DiscordLinkedAccountPlatform } from "./discord";
import { XLinkedAccountPlatform } from "./x";

export const PENDING_SOCIAL_QUEST_LOCAL_STORAGE_KEY = "pending_social_quest";

export interface ValidateLinkedAccountQuestResponse {
  following: boolean;
}

export interface LinkedAccountAuthResponse {
  access_token: string;
  refresh_token: string;
  external_user_id: string;
}

export interface LinkedAccountPlatform {
  imageUrl: string;
  name: string;
  questAction: string;
  getAuthorizeUrl: (loginOnly?: boolean) => string;
  login: (
    accessToken: string,
    code: string,
  ) => Promise<LinkedAccountAuthResponse>;
  validateQuest: (
    gameId: string,
    questId: string,
    accessToken: string,
  ) => Promise<ValidateLinkedAccountQuestResponse>;
  validateTweetQuest?: (
    gameId: string,
    questId: string,
    accessToken: string,
    startTime: string,
  ) => Promise<ValidateLinkedAccountQuestResponse>;
  isLoggedIn: () => boolean;
}

export interface PendingSocialQuest {
  gameId: string;
  ruleId: string;
  questLink: string;
  gameName: string;
  questKey: QuestKey;
  /**
   * If true, this will only link the social account without performing follow or quest validation.
   * Used when we only need to connect a social account without completing a quest.
   */
  loginOnly?: boolean;
}

export const linkedAccountPlatforms: Map<QuestKey, LinkedAccountPlatform> =
  new Map([
    ["discord-v2", DiscordLinkedAccountPlatform],
    ["x-v2", XLinkedAccountPlatform],
    ["x-share-validation", XLinkedAccountPlatform],
  ]);

export function openAuthorizeUrl(
  ruleId: string,
  key: QuestKey,
  questLink: string,
  gameName: string,
  gameId: string,
  loginOnly?: boolean,
) {
  const platform = linkedAccountPlatforms.get(key);
  if (!platform) {
    return;
  }

  const authorizeUrl = platform.getAuthorizeUrl();
  const pendingSocialQuest: PendingSocialQuest = {
    gameId,
    ruleId,
    questLink,
    gameName,
    questKey: key,
    loginOnly,
  };
  localStorage.setItem(
    PENDING_SOCIAL_QUEST_LOCAL_STORAGE_KEY,
    JSON.stringify(pendingSocialQuest),
  );
  window.open(authorizeUrl, "_blank");
}

export async function validateQuest(
  gameId: string,
  quest: Quest,
  accessToken: string,
  startTime: string,
) {
  const platform = linkedAccountPlatforms.get(quest.key);
  if (!platform) {
    throw new Error("Invalid quest key");
  }

  if (quest.key === "x-share-validation") {
    if (!platform.validateTweetQuest) {
      throw new Error("Platform does not support tweet validation");
    }
    // add buffer to ensure recent tweet posted is available for validation
    await new Promise((resolve) => setTimeout(resolve, 8000));
    return (
      await platform.validateTweetQuest(
        gameId,
        quest.ruleId,
        accessToken,
        startTime,
      )
    ).following;
  }

  return (await platform.validateQuest(gameId, quest.ruleId, accessToken))
    .following;
}
