import { useChests } from "@/hooks/api";
import { trackLegacyEvent } from "@analytics";
import { base } from "@biom3/design-tokens";
import { Button, Sticker } from "@biom3/react";
import { useIsLessThanMediumScreen, useTimeCountdown } from "@ui-kit";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClaimChestModal } from "../ClaimChestModal/ClaimChestModal";
import { VerticalDivider } from "../VerticalDivider/VerticalDivider";

const GameChestSourceToPageMap: Record<string, string> = {
  "6253fded-0d07-4e41-8239-e935f6f5748e-onboarding-chest-completed":
    "/games/treeverse",
  "d3592704-8398-4b2b-bb1d-ffd1c200354b-onboarding-completed":
    "/games/king-arthur-legends-rise",
};

const RewardChestNav = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isClaimChestModalOpen, setIsClaimChestModalOpen] = useState(false);
  const { chests, openChest } = useChests();

  // @dev: usePathname() can return null as per the docs
  // https://nextjs.org/docs/app/api-reference/functions/use-pathname
  const pathname = usePathname() as string | null;

  const searchParams = useSearchParams();

  const lockedChests = chests?.filter((x) => x.status === "locked");
  const lockedChest = lockedChests?.[0];

  // First openable chest
  const openableChests = chests?.filter((x) => x.status === "claimable");
  const openableChest = openableChests?.[0];

  const defaultTomorrow = new Date(
    new Date().setDate(new Date().getDate() + 1),
  );
  // Only calculate countdown if the chest exists, otherwise use a static value
  const { formatted: timeUntilUnlocked } = useTimeCountdown(
    lockedChest?.locked_until ? new Date(lockedChest.locked_until) : undefined,
  );
  const { formatted: timeUntilExpiration } = useTimeCountdown(
    openableChest?.expires_at ? new Date(openableChest.expires_at) : undefined,
  );
  const isSmallScreenMode = useIsLessThanMediumScreen();

  const handleOpenChest = () => {
    const lockedChestPath = GameChestSourceToPageMap[lockedChest.source] || "/";

    if (lockedChest) router.push(`${lockedChestPath}`);
    if (!openableChest) return;

    trackLegacyEvent({
      screen: "ChestModal",
      userJourney: "TreasureChest",
      action: "Opened",
      control: "Header",
      controlType: "Button",
      extras: {
        chest: openableChest,
      },
    });

    const chestGamePath = GameChestSourceToPageMap[openableChest.source] || "/";
    // Redirect to game page of the chest
    // TODO: use the right game page path, currently hardcoded to king arthur legends rise
    if (pathname?.startsWith(chestGamePath)) {
      setIsClaimChestModalOpen(true);
    } else {
      router.push(`${chestGamePath}?claim=${openableChest.source}`);
    }
  };

  const handleDoneClaiming = async () => {
    setIsClaimChestModalOpen(false);
    if (!openableChest) return;
    return await openChest(openableChest.id)();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: just on mount
  useEffect(() => {
    // No claim
    const claimSource = searchParams?.get("claim");
    if (!claimSource) {
      return;
    }
    const targetChest = chests?.find((x) => x.source === claimSource);
    const targetPage = GameChestSourceToPageMap[claimSource];
    // if current game page is the game page of the chest and has claimable chest,
    // auto open the chest modal only if redirected from navbar
    if (
      pathname?.startsWith(targetPage) &&
      targetChest?.status === "claimable" &&
      !isClaimChestModalOpen
    ) {
      setTimeout(() => {
        setIsClaimChestModalOpen(true);
      }, 1000);
    }
  }, [pathname, searchParams, chests]);

  const shouldShowChestMenu =
    lockedChests.length > 0 || openableChests.length > 0;

  const getOpenButtonText = () => {
    if (openableChest?.expires_at) {
      return (
        <span>
          Chest expires in{" "}
          <span
            style={{
              color: base.color.status.fatal.dim,
            }}
          >
            {timeUntilExpiration}
          </span>
        </span>
      );
    }
    if (openableChest) {
      return t("claim_chest");
    }
    return `${t("unlock_chest")} ${timeUntilUnlocked}`;
  };

  const renderStickerBadge = () => {
    if (openableChests.length > 1) {
      return (
        <Sticker.Badge
          showZero={false}
          badgeContent={openableChests.length.toString()}
        />
      );
    }
    if (openableChests.length === 1) {
      return <Sticker.Badge showZero={false} />;
    }
    return <Sticker.FramedIcon icon="Lock" size="small" circularFrame />;
  };

  const renderMobileButton = () => {
    if (shouldShowChestMenu) {
      return (
        <Sticker
          position={{
            x: "leftInside",
            y: "topInside",
          }}
          sx={{ right: "-25px" }}
        >
          {renderStickerBadge()}
          <Button variant="tertiary" onClick={handleOpenChest} size="medium">
            <Button.Icon icon="TreasureChest" />
          </Button>
        </Sticker>
      );
    }
  };

  const renderButton = () => {
    if (shouldShowChestMenu) {
      return (
        <>
          {openableChests.length > 1 ? (
            <Sticker
              position={{
                x: "left",
                y: "top",
              }}
            >
              {renderStickerBadge()}
              <Button variant="tertiary" onClick={handleOpenChest} size="small">
                <Button.Icon icon="TreasureChest" />
                {getOpenButtonText()}
              </Button>
            </Sticker>
          ) : (
            <Button variant="tertiary" onClick={handleOpenChest} size="small">
              <Button.Icon icon="TreasureChest" />
              {getOpenButtonText()}
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <>
      {!isSmallScreenMode ? renderButton() : renderMobileButton()}
      {!isSmallScreenMode && shouldShowChestMenu && <VerticalDivider />}
      <ClaimChestModal
        opened={isClaimChestModalOpen}
        close={handleDoneClaiming}
      />
    </>
  );
};

export { RewardChestNav };
