import { appConfig } from "@/constants";
import { notifyError } from "../monitoring";
import { authorizedPost } from "../request";
import type { LinkedAccountPlatform } from "./linkedAccounts";

const LOGIN_PAGE_NAME = "/x-login";
const X_CLIENT_ID = "UGdibjYzZWhXNEk0TGt0eHR3REw6MTpjaQ";
const X_CODE_CHALLENGE_LOCAL_STORAGE_KEY = "pending_x_code_challenge";
const X_ACCESS_TOKEN_LOCAL_STORAGE_KEY = "x_access_token";
const X_REFRESH_TOKEN_LOCAL_STORAGE_KEY = "x_refresh_token";
const X_USER_ID_LOCAL_STORAGE_KEY = "x_user_id";

export const XLinkedAccountPlatform: LinkedAccountPlatform = {
  imageUrl: "/images/quests/x.svg",
  name: "X",
  questAction: "follow",
  getAuthorizeUrl: () => {
    // Temporary: Clear any existing tokens until we add support for refreshing and handling errors
    localStorage.removeItem(X_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(X_REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(X_USER_ID_LOCAL_STORAGE_KEY);

    // generate code_challenge
    const array = new Uint32Array(8);
    self.crypto.getRandomValues(array);
    let codeChallenge = "";
    for (let i = 0; i < array.length; i++) {
      codeChallenge += array[i].toString(16);
    }
    localStorage.setItem(X_CODE_CHALLENGE_LOCAL_STORAGE_KEY, codeChallenge);

    const redirectUri = `${appConfig.BASE_REDIRECT_URL}${LOGIN_PAGE_NAME}`;
    return `https://x.com/i/oauth2/authorize?response_type=code&client_id=${X_CLIENT_ID}&redirect_uri=${redirectUri}&scope=users.read%20offline.access%20follows.write%20tweet.read&state=state&code_challenge=${codeChallenge}&code_challenge_method=plain`;
  },
  login: async (accessToken: string, code: string) => {
    const codeChallenge = localStorage.getItem(
      X_CODE_CHALLENGE_LOCAL_STORAGE_KEY,
    );
    if (!codeChallenge) {
      console.error("No code_verifier");
      notifyError(new Error("No code_verifier for x-login"), "xLogin");
      return;
    }

    const redirectUrl = `${appConfig.BASE_REDIRECT_URL}${LOGIN_PAGE_NAME}`;
    const response = await authorizedPost(
      "/passport-profile/v1/x-auth",
      { code, redirect_url: redirectUrl, code_verifier: codeChallenge },
      accessToken,
    );

    const { access_token, refresh_token, external_user_id } = response;
    if (!access_token || !refresh_token || !external_user_id) {
      throw new Error("Invalid response format from X authentication.");
    }

    localStorage.setItem(X_ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);
    localStorage.setItem(X_REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token);
    localStorage.setItem(X_USER_ID_LOCAL_STORAGE_KEY, external_user_id);

    return response;
  },
  validateQuest: async (
    gameId: string,
    questId: string,
    accessToken: string,
  ) => {
    return await authorizedPost(
      "/passport-profile/v1/x-quest",
      {
        x_access_token: localStorage.getItem(X_ACCESS_TOKEN_LOCAL_STORAGE_KEY),
        x_user_id: localStorage.getItem(X_USER_ID_LOCAL_STORAGE_KEY),
        quest_id: questId,
        game_id: gameId,
      },
      accessToken,
    );
  },
  isLoggedIn: () => {
    return !!localStorage.getItem(X_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  },
  validateTweetQuest: async (
    gameId: string,
    questId: string,
    accessToken: string,
    startTime: string,
  ) => {
    return await authorizedPost(
      "/passport-profile/v1/x-quest-validate-tweet",
      {
        x_access_token: localStorage.getItem(X_ACCESS_TOKEN_LOCAL_STORAGE_KEY),
        x_user_id: localStorage.getItem(X_USER_ID_LOCAL_STORAGE_KEY),
        quest_id: questId,
        game_id: gameId,
        start_time: startTime,
      },
      accessToken,
    );
  },
};
