import { useProfileDrawer } from "@/context/ProfileDrawerContext";
import { useRewardsProfileNotifications } from "@/hooks";
import { trackLegacyEvent } from "@analytics";
import { Box, ButtCon, Button, Drawer, type DrawerSize } from "@biom3/react";
import {
  useIsLessThanLargeScreen,
  useIsLessThanMediumScreen,
  useIsLessThanXXLargeScreen,
} from "@ui-kit/hooks/screenSizeHooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { AnimatePresence } from "motion/react";
import type { Dispatch, SetStateAction } from "react";
import { ConnectionsOverlay } from "./ConnectionsOverlay";
import { ProfileDrawerMain } from "./ProfileDrawerMain";
import { StatusHeaderPoc } from "./StatusPageOverlay/StatusHeaderPocs";
import { StatusPageOverlay } from "./StatusPageOverlay/StatusPageOverlay";

export type ProfileDrawerOverlay = undefined | "status" | "connections";

export function ProfileDrawer({
  setLoggingOut,
  setCopyWalletModalVisible,
  isCopyWalletModalVisible,
}: {
  setLoggingOut: Dispatch<SetStateAction<boolean>>;
  setCopyWalletModalVisible: Dispatch<SetStateAction<boolean>>;
  isCopyWalletModalVisible: boolean;
}) {
  const { isOpen, setIsOpen, openOverlay, setOpenOverlay } = useProfileDrawer();

  useRewardsProfileNotifications();

  const lessThanSmallScreen = useIsLessThanMediumScreen();
  const lessThanMediumScreen = useIsLessThanLargeScreen();
  const lessThanXLargeScreenMode = useIsLessThanXXLargeScreen();
  const { rewardsStatusHeaderPocEnabled } = useFlags();

  const onProfileClick = () => {
    trackLegacyEvent({
      userJourney: "Profile",
      screen: "Header",
      control: "Profile",
      controlType: "Button",
      action: "Pressed",
    });
    setIsOpen(true);
  };

  let drawerSize: DrawerSize = "quarter";
  if (lessThanSmallScreen) {
    drawerSize = "full";
  } else if (lessThanXLargeScreenMode) {
    drawerSize = "half";
  }

  return (
    <Drawer
      showHeaderBar={false}
      drawerPosition={lessThanSmallScreen ? "bottom" : "right"}
      size={drawerSize}
      containerSx={{
        "@media (min-width: 1441px)": {
          width: "30% !important",
        },
      }}
      bgOverlaySx={{
        backgroundColor: "base.color.translucent.inverse.700",
      }}
      onCloseDrawer={() => {
        setOpenOverlay(undefined);
        setIsOpen(false);
      }}
      visible={isOpen}
    >
      <Drawer.Target>
        {rewardsStatusHeaderPocEnabled ? (
          <StatusHeaderPoc onClick={onProfileClick} />
        ) : !lessThanMediumScreen ? (
          <Button size="medium" variant="primary" onClick={onProfileClick}>
            <Button.Icon icon="Profile" iconVariant="bold" />
            Profile
          </Button>
        ) : (
          <ButtCon icon="Profile" iconVariant="bold" onClick={onProfileClick} />
        )}
      </Drawer.Target>
      <Drawer.Content
        sx={{ backgroundColor: "base.color.translucent.inverse.800" }}
      >
        {({ closeDrawer }) => (
          <Box sx={{ position: "relative", h: "100%" }}>
            <ProfileDrawerMain
              closeDrawer={() => {
                closeDrawer();
                setIsOpen(false);
              }}
              setLoggingOut={setLoggingOut}
              setCopyWalletModalVisible={setCopyWalletModalVisible}
              isCopyWalletModalVisible={isCopyWalletModalVisible}
              openOverlay={setOpenOverlay}
            />
            {openOverlay ? (
              <AnimatePresence>
                <Box
                  sx={{
                    pos: "absolute",
                    inset: 0,
                    bg: "base.color.translucent.inverse.800",
                  }}
                >
                  {openOverlay === "status" ? (
                    <StatusPageOverlay
                      key="status"
                      onBack={() => setOpenOverlay(undefined)}
                    />
                  ) : openOverlay === "connections" ? (
                    <ConnectionsOverlay
                      key="connections"
                      onBack={() => setOpenOverlay(undefined)}
                    />
                  ) : null}
                </Box>
              </AnimatePresence>
            ) : null}
          </Box>
        )}
      </Drawer.Content>
    </Drawer>
  );
}
