import type * as checkout from "@imtbl/checkout-sdk";
import {
  type InfiniteData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";

import { appConfig } from "@/constants";
import { usePassportProvider } from "@/context";
import { useAccessToken } from "@/hooks/useAccessToken";
import type {
  ChainAddress,
  CoinBalance,
  Collection,
  Item,
  ItemData,
  ItemDetails,
  ItemOption,
  ItemV2,
  MinimalCoinBalance,
  Network,
  Page,
  TokenID,
  ZkEvmItemV2,
} from "@/types";
import { authorizedGet } from "@/utils/request";
import { getMediaUrl } from "@/utils/util";
import { itemMetadataTransformer } from "./useQuery/itemMetadataTransformer";

const iconSource = appConfig.TOKEN_ICONS_URL;
export const COLLECTIONS_PAGE_SIZE = 30;
const ITEMS_INITIAL_PAGE_SIZE = 29;
const ITEMS_V2_PAGE_SIZE = 20;

// TODO @pano @ji: Refactor FiatPricingProvider so it can be reused across zkEVM and starkEx
//      For now, we are mantaining a list of tokens to fetch prices for in addition to the `supportedCurrencies`
const zkEVMCurrencies = ["gog", "usdt"];

const getImageUrl = (token: checkout.TokenInfo) => {
  if (token.icon) return token.icon;
  if (token.address === "native") return `${iconSource}/imx.svg`;
  if (token.address) return `${iconSource}/${token.address.toLowerCase()}.svg`;
  return null;
};

export function useCoinBalancesZkEVMQuery(
  walletAddress: string | undefined,
  checkoutClient: checkout.Checkout,
  checkoutNetwork: checkout.NetworkInfo | undefined,
): {
  data: MinimalCoinBalance[] | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  const chainId = checkoutNetwork ? checkoutNetwork.chainId : null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, CoinBalance[], any>({
    queryKey: ["coin-balances-zkevm", walletAddress, chainId],
    enabled: !!walletAddress && !!checkoutNetwork,
    refetchInterval: 1000 * 15, // 15 seconds
    queryFn: async () => {
      if (!walletAddress || !chainId) {
        return [];
      }
      const data = await checkoutClient.getAllBalances({
        walletAddress: walletAddress,
        chainId,
      });
      return data.balances.map((coinBalance) => ({
        name: coinBalance.token.name,
        symbol: coinBalance.token.symbol,
        contractAddress: coinBalance.token.address,
        balance: coinBalance.balance.toString(),
        imageUrl: getImageUrl(coinBalance.token),
        decimals: coinBalance.token.decimals,
      }));
    },
  });
}

export function useCoinBalancesQuery(network: Network): {
  data: CoinBalance[] | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  const getAccessToken = useAccessToken();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, CoinBalance[], any>({
    queryKey: ["coin-balances-imtbl-x"],
    refetchInterval: 1000 * 15, // 15 seconds
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return undefined;
      return authorizedGet(
        `/passport-profile/v1/networks/${network}/coins`,
        accessToken,
      );
    },
    select: (data) =>
      data.map(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (coinBalance: any) =>
          <CoinBalance>{
            network: coinBalance.network,
            name: coinBalance.name,
            symbol: coinBalance.symbol,
            contractAddress: coinBalance.contract_address,
            balance: coinBalance.balance,
            imageUrl: coinBalance.image_url,
            decimals: coinBalance.decimals,
            wallet: coinBalance.wallet,
            walletType: coinBalance.wallet_type,
          },
      ),
  });
}

async function getSupportedCurrencies() {
  const apiKey = appConfig.MOONPAY_API_KEY;
  const resp = await axios.get(
    `https://api.moonpay.com/v3/currencies?apiKey=${apiKey}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  const response = resp.data
    .filter((currency: { type: string }) => currency.type === "crypto")
    .map((currency: { code: string }) =>
      currency.code.replace("_immutable", ""),
    );

  return [...response, ...zkEVMCurrencies];
}

export function useSupportedCurrenciesQuery(): {
  data: string[];
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  const { data, error, isLoading } = useQuery({
    queryKey: ["supported-currencies"],
    queryFn: getSupportedCurrencies,
  });

  return {
    data: data ?? [], // TODO: Data can be undefined!
    error,
    isLoading,
  };
}

function getCollectionParams(
  pageSize: number,
  filterAddress: string,
  cursor: string,
): URLSearchParams {
  const params = new URLSearchParams({ page_size: pageSize.toString() });
  // Empty strings are invalid
  if (filterAddress) params.append("address", filterAddress.toLowerCase());
  if (cursor) params.append("page_cursor", cursor);
  return params;
}

export async function getCollections(
  getAccessToken: () => Promise<string | undefined>,
  network: Network,
  cursor: string,
  pageSize: number,
  filterAddress: string,
  blacklistedAddresses: string[] | undefined,
  passportWallet: string | undefined,
) {
  const accessToken = await getAccessToken();
  if (!accessToken) throw new Error("No access token");

  const params = getCollectionParams(pageSize, filterAddress, cursor);
  const url = `/passport-profile/v1/networks/${network}/collections?${params.toString()}`;

  const response = await authorizedGet(url, accessToken);
  const result = response.result || [];
  const nextCursor = response.page.next_cursor || "";

  const collections: Collection[] = result
    .filter(
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (collection: any) =>
        !(
          blacklistedAddresses?.includes(collection.contract_address) ||
          collection.name.toLowerCase().includes("airdrop")
        ),
    )
    .map(
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (collection: any) =>
        <Collection>{
          id: collection.network + collection.contract_address,
          network: collection.network,
          name: collection.name,
          symbol: collection.symbol,
          address: collection.contract_address,
          image: getMediaUrl(collection.image),
          icon: collection.icon,
          balance: Number.parseInt(collection.balance),
          owners: collection.owners,
          hasImportableAssets: collection.owners
            ? collection.owners.filter(
                (owner: string) => owner !== passportWallet,
              ).length > 0
            : false,
          tokenType: collection.token_type,
        },
    );

  return {
    cursor: nextCursor,
    collections,
  };
}

export function useCollectionsQuery(
  address: string | undefined,
  enabled: boolean,
) {
  const getAccessToken = useAccessToken();
  const { walletAddress } = usePassportProvider();
  const flags = useFlags();
  const blacklistedCollections = flags.collectionsDenyList?.split(",");

  // For zkEvm
  const {
    data: zkEvmData,
    error: zkEvmError,
    isLoading: zkEvmIsLoading,
    fetchNextPage: fetchNextPageZkEvm,
    hasNextPage: hasNextPageZkEvm,
    isFetchingNextPage: isFetchingNextPageZkEvm,
    refetch: refetchZkEvm,
  } = useInfiniteQuery<{
    cursor: string;
    collections: Collection[];
  }>({
    queryKey: ["collectionsZkEvm", address],
    queryFn: ({ pageParam = "" }) =>
      getCollections(
        getAccessToken,
        "zkEvm",
        pageParam as string,
        COLLECTIONS_PAGE_SIZE,
        address ?? "",
        blacklistedCollections,
        walletAddress,
      ),
    getNextPageParam: (lastPage) =>
      lastPage.cursor && lastPage.cursor !== "" ? lastPage.cursor : undefined,
    initialPageParam: "",
    enabled: enabled,
  });

  // For starkEx
  const {
    data: starkExData,
    error: starkExError,
    isLoading: starkExIsLoading,
    fetchNextPage: fetchNextPageStarkEx,
    hasNextPage: hasNextPageStarkEx,
    isFetchingNextPage: isFetchingNextPageStarkEx,
    refetch: refetchStarkEx,
  } = useInfiniteQuery<{
    cursor: string;
    collections: Collection[];
  }>({
    queryKey: ["collectionsStarkEx", address],
    queryFn: ({ pageParam = "" }) =>
      getCollections(
        getAccessToken,
        "starkEx",
        pageParam as string,
        COLLECTIONS_PAGE_SIZE,
        address ?? "",
        blacklistedCollections,
        walletAddress,
      ),
    getNextPageParam: (lastPage) =>
      lastPage.cursor && lastPage.cursor !== "" ? lastPage.cursor : undefined,
    initialPageParam: "",
    enabled: enabled && !address,
  });

  return {
    zkEvmData,
    zkEvmError,
    zkEvmIsLoading,
    fetchNextPageZkEvm,
    hasNextPageZkEvm,
    isFetchingNextPageZkEvm,
    refetchZkEvm,
    starkExData,
    starkExError,
    starkExIsLoading,
    fetchNextPageStarkEx,
    hasNextPageStarkEx,
    isFetchingNextPageStarkEx,
    refetchStarkEx,
  };
}

export function useItemDetailsQuery(
  network: Network | undefined,
  collection: ChainAddress | undefined,
  tokenId: TokenID | undefined,
  owner: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): { data: ItemDetails | undefined; error: any; isLoading: boolean } {
  const getAccessToken = useAccessToken();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, ItemDetails, any>({
    queryKey: ["item-details", network, collection, tokenId],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return undefined;
      return authorizedGet(
        `/passport-profile/v1/networks/${network}/collections/${collection}/items/${tokenId}?owner=${owner}`,
        accessToken,
      );
    },
    enabled: !!network && !!collection && !!tokenId,
    select: (data) =>
      <ItemDetails>{
        discriminator: network === "starkEx" ? "STARK_EX" : "ZK_EVM",
        network: data.network,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        collection: collection!,
        collectionName: data.collection_name,
        tokenId: data.token_id,
        owner: data.owner,
        status: data.status,
        name: data.name,
        description: data.description,
        image: getMediaUrl(data.image),
        animationUrl: getMediaUrl(data.animation_url),
        metadata: itemMetadataTransformer(data),
        createdAt: data.created_at ? new Date(data.created_at) : undefined,
        updatedAt: data.updated_at ? new Date(data.updated_at) : undefined,
        balance: data.balance,
        tokenType: data.token_type,
      },
  });
}

function getItemsParams(
  filterAddress: string,
  cursor: string,
  sortBy: string,
  sortDirection: string,
  pageSize: number = ITEMS_INITIAL_PAGE_SIZE,
): URLSearchParams {
  const params = new URLSearchParams({
    page_size: pageSize.toString(),
    sort_by: sortBy,
    sort_direction: sortDirection,
  });
  // Empty strings are invalid
  if (filterAddress) params.append("address", filterAddress.toLowerCase());
  if (cursor) params.append("page_cursor", cursor);
  return params;
}

async function fetchItems(
  selectedOption: ItemOption,
  network: "starkEx" | "zkEvm" | undefined,
  address: string,
  getAccessToken: () => Promise<string | undefined>,
  cursor?: string,
  filterAddress?: string,
): Promise<ItemData> {
  const accessToken = await getAccessToken();
  if (!accessToken || !address) throw new Error("No access token or address");
  const params = getItemsParams(
    filterAddress ?? "",
    cursor ?? "",
    selectedOption.sortBy,
    selectedOption.sortDirection,
  );
  const url = `/passport-profile/v1/networks/${network}/collections/${address}/items?${params.toString()}`;

  const response: { page: Page; result: Item[] } = await authorizedGet(
    url,
    accessToken,
  );
  return <ItemData>{
    nextCursor: response.page.next_cursor,
    items: response.result.map(
      (item) =>
        <ItemDetails>{
          network: item.network,
          name: item.name,
          description: item.description,
          image: getMediaUrl(item.image),
          animationUrl: getMediaUrl(item.animation_url),
          tokenId: item.token_id,
          collection: address,
          owner: item.owner,
          balance: item.balance,
          tokenType: item.token_type,
        },
    ),
  };
}

async function fetchZkEvmItemsV2(
  selectedOption: ItemOption,
  address: string,
  getAccessToken: () => Promise<string | undefined>,
  cursor?: string,
  filterAddress?: string,
): Promise<ItemData> {
  const accessToken = await getAccessToken();
  if (!accessToken || !address) throw new Error("No access token or address");
  const params = getItemsParams(
    filterAddress ?? "",
    cursor ?? "",
    selectedOption.sortBy,
    selectedOption.sortDirection,
    ITEMS_V2_PAGE_SIZE,
  );
  const url = `/passport-profile/v2/networks/zkEvm/collections/${address}/items?${params.toString()}`;

  const response: { page: Page; result: ItemV2[] } = await authorizedGet(
    url,
    accessToken,
  );
  return <ItemData>{
    nextCursor: response.page.next_cursor,
    items: response.result.map(
      (item) =>
        <ZkEvmItemV2>{
          discriminator: "ZKEVM_ITEM_V2",
          network: item.network,
          name: item.name,
          description: item.description,
          image: getMediaUrl(item.image),
          animationUrl: getMediaUrl(item.animation_url),
          tokenId: item.token_id,
          collection: address,
          owner: item.owner,
          balance: item.balance,
          tokenType: item.token_type,
          acquiredAt: item.acquired_at,
          marketData: item.market_data,
          metadata: item.metadata,
          bids: item.bids,
        },
    ),
  };
}

export function useItemsQuery({
  view,
  network,
  address,
  selectedOption,
  filterAddress,
}: {
  view: string;
  network: Network;
  address: string;
  selectedOption: ItemOption;
  filterAddress?: string;
}): {
  isFetching: boolean;
  isLoading: boolean;
  data: InfiniteData<ItemData, unknown> | undefined;
  error: unknown;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  status: string;
  refetch: () => void;
} {
  const getAccessToken = useAccessToken();
  const { pricingDataEnabled } = useFlags();

  const {
    data,
    error,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery<ItemData>({
    queryKey: ["items", selectedOption, network, address, view],
    queryFn: (pageParam: unknown) => {
      const page: { pageParam?: string } = pageParam as { pageParam: string };
      if (network === "zkEvm" && pricingDataEnabled) {
        return fetchZkEvmItemsV2(
          selectedOption,
          address,
          getAccessToken,
          page.pageParam,
          filterAddress,
        );
      }
      return fetchItems(
        selectedOption,
        network,
        address,
        getAccessToken,
        page.pageParam,
        filterAddress,
      );
    },
    // Needs to return undefined in order for hasNextPage to return false
    getNextPageParam: (lastPage: ItemData) =>
      lastPage?.nextCursor && lastPage.nextCursor !== ""
        ? lastPage.nextCursor
        : undefined,
    initialPageParam: "",
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
  };
}

export { itemMetadataTransformer };
