import { useNotification } from "@/context/NotificationProvider";
import { useRewardsProfile } from "@/hooks";
import { useEffect, useRef } from "react";

const NOTIFICATION_DURATION = 5000;

export const useRewardsProfileNotifications = () => {
  const { profile } = useRewardsProfile();
  const prevProfile = useRef(profile);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (!profile) return;
    // Check if status changed
    if (
      prevProfile.current !== undefined &&
      profile.status !== prevProfile.current.status
    ) {
      showNotification(`Status increased! You are now ${profile.status}`, {
        variant: "success",
        duration: NOTIFICATION_DURATION,
      });
    }
    // Check if level increased
    else if (
      prevProfile.current !== undefined &&
      profile.level > prevProfile.current.level
    ) {
      showNotification(`Congratulations! You are now level ${profile.level}`, {
        variant: "success",
        duration: NOTIFICATION_DURATION,
      });
    }

    prevProfile.current = profile;
  }, [profile, showNotification]);
};
