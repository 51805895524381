import { linkedAccountPlatforms } from "@/utils/linkedaccounts/linkedAccounts";
import { trackLegacyEvent } from "@analytics";
import {
  Body,
  Box,
  ButtCon,
  Button,
  CloudImage,
  Heading,
  Icon,
  Modal,
} from "@biom3/react";
import {
  MODAL_HEIGHT,
  MODAL_WIDTH,
  SOCIAL_LINK_ERROR_MESSAGES,
  appConfig,
} from "@constants";
import type { QuestState } from "@ui-kit/QuestList";
import { useCallback, useEffect, useMemo, useState } from "react";

export type FollowConfirmationModalProps = {
  visible: boolean;
  onConfirm: () => Promise<void> | void;
  onRetry: () => Promise<void> | void;
  onQuestClick: () => Promise<void> | void;
  onClose: () => void;
  error?: string | null;
  setError: (error: string | null) => void;
  quest?: QuestState;
  onSkip?: (quest: QuestState) => void;
  gameName: string;
  isSkipQuestVariantB?: boolean;
  skipSocialValidationQuestVariant?: string;
};

type ModalButtonsProps = {
  error?: string | null;
  isProcessing: boolean;
  isConfirming: boolean;
  isLoading: boolean;
  onErrorCTA: () => void;
  onConfirmCTA: () => void;
  onSkipCTA: () => void;
  skippable?: boolean;
  isSkipQuestVariantB?: boolean;
};

const ALREADY_LINKED_ERRORS = [
  SOCIAL_LINK_ERROR_MESSAGES.SOCIAL_ACCOUNT_ALREADY_LINKED,
  SOCIAL_LINK_ERROR_MESSAGES.DIFFERENT_SOCIAL_ACCOUNT_ALREADY_LINKED,
];

function getCustomErrorContent(
  error: string,
  platform?: { name: string; questAction: string },
): { heading: string; body: string; ctaLabel: string } {
  const platformName = platform?.name ?? "";

  switch (error) {
    case SOCIAL_LINK_ERROR_MESSAGES.SOCIAL_ACCOUNT_ALREADY_LINKED:
      return {
        heading: "Account already in use",
        body: `This account is already connected to another Passport. Please use a different ${platformName} account to proceed`,
        ctaLabel: "Try again",
      };
    case SOCIAL_LINK_ERROR_MESSAGES.DIFFERENT_SOCIAL_ACCOUNT_ALREADY_LINKED:
      return {
        heading: "Account didn't match",
        body: `You previously used a different ${platformName} account. Make sure you're using that account to proceed`,
        ctaLabel: "Try again",
      };
    default:
      return {
        heading: "That didn't work.\nLet's try again",
        body: "",
        ctaLabel: "Try again",
      };
  }
}

function ButtonLayout({
  error,
  isProcessing,
  isConfirming,
  isLoading,
  onErrorCTA,
  onConfirmCTA,
  onSkipCTA,
  skippable,
  isSkipQuestVariantB,
}: ModalButtonsProps) {
  if (error) {
    return (
      <Box
        sx={{
          d: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "90%",
          mb: "base.spacing.x8",
          gap: "base.spacing.x2",
        }}
      >
        <Button
          size="large"
          variant="primary"
          onClick={onErrorCTA}
          disabled={isProcessing || isConfirming}
        >
          Try again
        </Button>
        {skippable && (
          <Button
            size="large"
            variant="tertiary"
            onClick={onSkipCTA}
            disabled={isProcessing || isConfirming}
          >
            I'll do this later
          </Button>
        )}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        d: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        mb: "base.spacing.x8",
        gap: "base.spacing.x2",
      }}
    >
      {!isLoading && (
        <Button
          size="large"
          variant="primary"
          onClick={onConfirmCTA}
          disabled={isProcessing || isConfirming}
        >
          {isConfirming ? (
            <Icon
              icon="Loading"
              sx={{ fill: "inherit", w: "base.icon.size.200" }}
            />
          ) : (
            "I've done this"
          )}
        </Button>
      )}

      {skippable && !isSkipQuestVariantB && (
        <Button
          size="large"
          variant="tertiary"
          onClick={onSkipCTA}
          disabled={isProcessing || isConfirming}
        >
          I'll do this later
        </Button>
      )}
    </Box>
  );
}

export function FollowConfirmationModal({
  visible,
  onConfirm,
  onRetry,
  onQuestClick,
  onClose,
  error,
  setError,
  quest,
  onSkip,
  gameName,
  isSkipQuestVariantB,
  skipSocialValidationQuestVariant,
}: FollowConfirmationModalProps) {
  const questKey = quest?.key;
  const linkUrl = quest?.linkUrl;
  const ruleId = quest?.ruleId || "";
  const skippable = quest?.skippable || false;

  const [flowState, setFlowState] = useState({
    isLoading: true,
    isProcessing: false, // tracking "Try again"
    isConfirming: false, // tracking "I've done this"
  });

  const { isLoading, isProcessing, isConfirming } = flowState;

  const setFlow = useCallback((partial: Partial<typeof flowState>) => {
    setFlowState((prev) => ({ ...prev, ...partial }));
  }, []);

  const platform = useMemo(
    () => (questKey ? linkedAccountPlatforms.get(questKey) : undefined),
    [questKey],
  );

  const isAlreadyLinkedError = error
    ? ALREADY_LINKED_ERRORS.includes(error)
    : false;

  const customErrorContent = useMemo(() => {
    if (!error) return null;
    return getCustomErrorContent(error, platform);
  }, [error, platform]);

  useEffect(() => {
    if (visible) {
      setFlowState({
        isLoading: true,
        isProcessing: false,
        isConfirming: false,
      });
    }
  }, [visible]);

  useEffect(() => {
    // Determine if the user has successfully logged in on the other tab
    const handleVisibilityChange = () => {
      if (!document.hidden && platform && platform.isLoggedIn()) {
        setFlow({ isLoading: false });
        // For quests that complete in the login screen immediately close the modal when they return to this tab
        if (localStorage.getItem(ruleId) === "true") {
          onConfirm();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [platform, ruleId, onConfirm, setFlow]);

  const trackButtonPress = useCallback(
    (control: string) => {
      trackLegacyEvent({
        screen: "FollowConfirmationModal",
        userJourney: "Games",
        action: "Pressed",
        control,
        controlType: "Button",
        extras: {
          ruleId,
          variant: skipSocialValidationQuestVariant,
        },
      });
    },
    [ruleId, skipSocialValidationQuestVariant],
  );

  const handleErrorCTA = useCallback(async () => {
    trackButtonPress(
      isAlreadyLinkedError ? "TryAgainExternalLink" : "TryAgain",
    );
    setFlow({ isProcessing: true });
    if (isAlreadyLinkedError) {
      await onRetry();
    } else {
      await onQuestClick();
    }
    setFlow({ isProcessing: false });
    setError(null);
  }, [
    isAlreadyLinkedError,
    onRetry,
    onQuestClick,
    setFlow,
    setError,
    trackButtonPress,
  ]);

  const handleConfirmCTA = useCallback(async () => {
    trackButtonPress("Confirm");
    setFlow({ isConfirming: true });
    await onConfirm();
    setFlow({ isConfirming: false });
  }, [onConfirm, trackButtonPress, setFlow]);

  const handleSkipCTA = useCallback(() => {
    trackButtonPress("Skip");
    if (onSkip && quest) {
      onSkip(quest);
    }
    onClose();
  }, [onClose, trackButtonPress, onSkip, quest]);

  const handleClose = useCallback(() => {
    trackButtonPress("Close");
    onClose();
  }, [onClose, trackButtonPress]);

  const errorHeading = customErrorContent?.heading;
  const errorBody = customErrorContent?.body;

  const promptConfirmationText =
    platform?.name === "X"
      ? `Did you tweet about ${gameName} on ${platform?.name}?`
      : `Did you join ${gameName} on ${platform?.name}?`;

  const promptConfirmationLink =
    platform?.name === "X" ? "X" : linkUrl?.replace(/^https?:\/\//, "");

  const promptConfirmationAction =
    platform?.name === "X"
      ? "and hit post."
      : `and hit ${platform?.questAction}.`;

  return (
    <Modal
      visible={visible}
      onCloseModal={onClose}
      showBgOverlay={false}
      outsideClicksClose={false}
    >
      <Modal.Content
        sx={{
          d: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "base.borderRadius.x10",
          position: "relative",
          overflow: "hidden",
          background: "base.color.brand.2",
          backgroundImage: `url("${appConfig.ASSET_BASE_URL}/images/quests/follow-confirmation-background.svg")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          height: MODAL_HEIGHT,
          width: {
            default: "calc(100dvw - 40px)",
            small: MODAL_WIDTH,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "base.spacing.x4",
          }}
        >
          {platform?.imageUrl && (
            <CloudImage
              imageUrl={`${appConfig.ASSET_BASE_URL}${platform.imageUrl}`}
              relativeImageSizeInLayout="64px"
              sx={{ my: "base.spacing.x32", width: "64px" }}
            />
          )}

          <Heading
            size="medium"
            weight="bold"
            sx={{ mx: "base.spacing.x12", textAlign: "center" }}
          >
            {error
              ? errorHeading
              : isLoading
                ? `Waiting for you to login to ${platform?.name}...`
                : promptConfirmationText}
          </Heading>

          {error ? (
            <Body
              sx={{ mx: "base.spacing.x12", textAlign: "center" }}
              size="small"
            >
              {errorBody}
            </Body>
          ) : !isLoading ? (
            <Body
              size="small"
              sx={{ mx: "base.spacing.x12", textAlign: "center" }}
            >
              Head over to{" "}
              <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#F191FA", textDecoration: "none" }}
              >
                {promptConfirmationLink}
              </a>{" "}
              {promptConfirmationAction}
            </Body>
          ) : null}
        </Box>
        <ButtonLayout
          error={error}
          isProcessing={isProcessing}
          isConfirming={isConfirming}
          isLoading={isLoading}
          onErrorCTA={handleErrorCTA}
          onConfirmCTA={handleConfirmCTA}
          onSkipCTA={handleSkipCTA}
          skippable={skippable}
          isSkipQuestVariantB={isSkipQuestVariantB}
        />
        <ButtCon
          icon="Close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "base.spacing.x4",
            right: "base.spacing.x4",
          }}
          variant="tertiary"
        />
      </Modal.Content>
    </Modal>
  );
}
