import { useRewardsProfile } from "@/hooks/";
import { Body, Box, MenuItem, ProgressBar } from "@biom3/react";
import { AnimatePresence, motion } from "motion/react";

interface StatusLevelProps {
  onClick?: () => void;
}

function AnimateInputChange({
  input,
}: {
  input?: string | number;
}) {
  if (input === undefined) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.span
        key={input}
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        {input}
      </motion.span>
    </AnimatePresence>
  );
}

export function StatusLevel({ onClick }: StatusLevelProps) {
  const { profile, progress, isLoading } = useRewardsProfile();

  return (
    <MenuItem
      shimmer={isLoading ? "withBottomSlot" : false}
      shimmerSx={{ height: "92px" }}
      emphasized
      size="xSmall"
      sx={{ width: "100%" }}
      onClick={onClick}
    >
      <MenuItem.Label>
        <AnimateInputChange input={progress?.status.current.label} />
      </MenuItem.Label>
      <MenuItem.Caption sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", display: "flex", py: "base.spacing.x2" }}>
          <AnimateInputChange input={profile?.xp} />
          <Body
            size="xSmall"
            sx={{ px: "base.spacing.x1", color: "base.color.text.secondary" }}
          >
            /
          </Body>
          <AnimateInputChange input={progress?.status.next.requirements.xp} />
          &nbsp;XP
          <Body
            size="xSmall"
            color="base.color.text.primary"
            sx={{ ml: "auto" }}
          >
            <AnimateInputChange input={progress?.status.next.label} />
          </Body>
        </Box>

        <ProgressBar
          variant="determinate"
          colorVariant="gradient"
          value={progress?.status.percentage || 0}
          hideText
          sx={{
            mb: "base.spacing.x4",
            bg: "base.color.translucent.emphasis.300",
            borderRadius: "base.borderRadius.x4",
          }}
        />
      </MenuItem.Caption>
    </MenuItem>
  );
}
