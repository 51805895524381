interface DividerProps {
  gap?: string | number; // Gap between the elements
  color?: string; // Color of the divider
}

const VerticalDivider = ({ gap = "1rem", color }: DividerProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap }}>
      <div
        style={{
          width: "2px",
          height: "24px", // Adjust the height as needed
          backgroundColor: color || "#515151",
        }}
      />
    </div>
  );
};

export { VerticalDivider };
