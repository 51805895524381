import {
  Body,
  Box,
  Button,
  Caption,
  Divider,
  Heading,
  MenuItem,
} from "@biom3/react";

export function StatusRequirements() {
  return (
    <MenuItem emphasized sx={{ padding: "base.spacing.x6" }}>
      <MenuItem.BottomSlot sx={{ width: "100%", padding: "0px" }}>
        <Caption
          size="medium"
          weight="bold"
          sx={{
            color: "base.color.text.body.primary",
            mb: "base.spacing.x4",
          }}
        >
          HOW TO GET TO STATUS 2
        </Caption>

        <Divider size="xSmall" sx={{ mb: "base.spacing.x2" }} />

        <Heading
          size="xSmall"
          weight="bold"
          sx={{
            mb: "base.spacing.x2",
          }}
        >
          475 more XP
        </Heading>

        <Body size="xxSmall" sx={{ color: "base.color.text.body.primary" }}>
          Earn XP by completing tasks on Immutable Play.
        </Body>

        <Body
          size="xxSmall"
          sx={{
            color: "base.color.text.body.secondary",
            py: "base.spacing.x2",
          }}
        >
          and
        </Body>

        <Heading
          size="xSmall"
          sx={{
            mb: "base.spacing.x4",
          }}
        >
          Link an account
        </Heading>
        <Box
          sx={{
            display: "flex",
            gap: "base.spacing.x2",
            flexWrap: "wrap",
          }}
        >
          <Button variant="tertiary" size="small">
            Connect
            <Button.Icon icon="X" />
          </Button>
          <Button variant="tertiary" size="small">
            Connect
            <Button.Icon icon="Discord" />
          </Button>
          <Button variant="tertiary" size="small">
            Connect
            <Button.Icon icon="SteamStore" />
          </Button>
        </Box>
      </MenuItem.BottomSlot>
    </MenuItem>
  );
}
