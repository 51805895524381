import type {
  ProgressionLevel,
  ProgressionStatus,
  RewardsProfileResponse,
  RewardsProgressionResponse,
} from "./useRewardsProfile";

export const findCurrentStatus = (
  rewardsProfile: RewardsProfileResponse,
  rewardsProgression: RewardsProgressionResponse,
) => {
  return rewardsProgression.statuses.find(
    (status) => status.label === rewardsProfile.status,
  );
};

export const findNextStatus = (
  rewardsProgression: RewardsProgressionResponse,
  currentStatus: ProgressionStatus,
) => {
  const currentStatusIndex = rewardsProgression.statuses.findIndex(
    (status) => status.label === currentStatus?.label,
  );

  return (
    rewardsProgression.statuses[currentStatusIndex + 1] || {
      label: "Max Status!",
      requirements: {
        xp: currentStatus.requirements.xp,
      },
    }
  );
};

export const findCurrentLevel = (
  rewardsProfile: RewardsProfileResponse,
  rewardsProgression: RewardsProgressionResponse,
) => {
  return rewardsProgression.levels.find(
    (level) => level.id === rewardsProfile.level,
  );
};

export const findNextLevel = (
  rewardsProgression: RewardsProgressionResponse,
  currentLevel: number,
) => {
  const currentLevelIndex = rewardsProgression.levels.findIndex(
    (level) => level.id === currentLevel,
  );

  return (
    rewardsProgression.levels[currentLevelIndex + 1] || {
      level: currentLevel,
      requirements: {
        xp: 0,
      },
    }
  );
};

export const calculateProgressPercentage = <
  T extends ProgressionLevel | ProgressionStatus,
>(
  current: T,
  next: T,
  rewardsProfile: RewardsProfileResponse,
) => {
  const percentage =
    ((rewardsProfile.xp - current.requirements.xp) /
      (next.requirements.xp - current.requirements.xp)) *
    100;
  return percentage > 100 ? 100 : percentage || 0;
};
