import { useRewardsProfile } from "@/hooks";
import { AspectRatioImage, Box, ButtCon, Caption, Stack } from "@biom3/react";
import { motion } from "motion/react";
import { StatusLevel } from "./StatusLevel";
import { StatusRequirements } from "./StatusRequirements";
interface StatusPageOverlayProps {
  onBack: () => void;
}

export function StatusPageOverlay({ onBack }: StatusPageOverlayProps) {
  const { profile } = useRewardsProfile();

  return (
    <Box
      rc={
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", bounce: 0, duration: 0.4 }}
        />
      }
      sx={{ height: "100%", bg: "base.color.fixed.black.1000" }}
    >
      <Box
        sx={{
          position: "relative",
          padding: "base.spacing.x2",
        }}
      >
        <AspectRatioImage
          use={
            <img src="/images/profile/profile-status-background.webp" alt="" />
          }
          aspectRatio="20/8"
          sx={{
            w: "100%",
            borderRadius: "base.spacing.x2",
            position: "relative",
          }}
        />
        <ButtCon
          icon="ChevronBackward"
          variant="tertiary/inverse"
          iconVariant="bold"
          sx={{
            pos: "absolute",
            top: "base.spacing.x5",
            left: "base.spacing.x5",
          }}
          onClick={onBack}
        />
        <Caption
          size="medium"
          weight="bold"
          sx={{
            textAlign: "center",
            py: "base.spacing.x5",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            color: "base.color.text.heading.inverse.primary",
          }}
        >
          {profile?.status}
        </Caption>
      </Box>

      <Stack
        sx={{
          px: "base.spacing.x6",
          width: "100%",
        }}
      >
        <StatusLevel />
        <StatusRequirements />
      </Stack>
    </Box>
  );
}
