import { usePassportProvider } from "@/context";

import { toSimpleAddressFormat } from "@/utils/util";
import {
  AspectRatioImage,
  Box,
  ButtCon,
  Button,
  FramedIcon,
  MenuItem,
  Stack,
} from "@biom3/react";
import { useIsLessThanXXLargeScreen } from "@ui-kit/hooks/screenSizeHooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import Link from "next/link";
import type { Dispatch, SetStateAction } from "react";
import { CopyWalletModal } from "../coin-balances/CopyWalletModal";
import { LegalLinks } from "./LegalLinks";
import type { ProfileDrawerOverlay } from "./ProfileDrawer";
import { StatusLevel } from "./StatusPageOverlay/StatusLevel";

interface ProfileDrawerMainProps {
  closeDrawer: () => void;
  setLoggingOut: Dispatch<SetStateAction<boolean>>;
  setCopyWalletModalVisible: Dispatch<SetStateAction<boolean>>;
  isCopyWalletModalVisible: boolean;
  openOverlay: (overlay: ProfileDrawerOverlay) => void;
}

export function ProfileDrawerMain({
  closeDrawer,
  setLoggingOut,
  setCopyWalletModalVisible,
  isCopyWalletModalVisible,
  openOverlay,
}: ProfileDrawerMainProps) {
  const { walletAddress, logout } = usePassportProvider();
  const {
    rewardsProfileExperienceEnabled,
    gemEarningHistoryEnabled,
    weeklyRewardsEligibilityEnabled,
  } = useFlags();

  const lessThanXXLargeScreenMode = useIsLessThanXXLargeScreen();
  const menuItemSize = lessThanXXLargeScreenMode ? "xSmall" : "small";

  const onCopyWalletAddress = () => {
    setCopyWalletModalVisible(true);
    walletAddress && navigator.clipboard.writeText(walletAddress);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <AspectRatioImage
          use={<img src="/images/profile/profile-background.webp" alt="" />}
          aspectRatio="20/8"
          sx={{ w: "100%", borderRadius: "base.spacing.x2" }}
        />
        <ButtCon
          icon="Close"
          variant="tertiary"
          iconVariant="bold"
          sx={{
            pos: "absolute",
            top: "base.spacing.x5",
            left: "base.spacing.x5",
          }}
          onClick={closeDrawer}
        />
        <FramedIcon
          icon="Profile"
          size="xLarge"
          emphasized={true}
          variant={"bold"}
          circularFrame={true}
          sx={{
            position: "absolute",
            bottom: "0",
            left: "50%",
            translate: "-50% 40%",
            background: "base.color.translucent.inverse.1000",
            boxShadow: ({ base }) =>
              `0px 0px 0px ${base.border.size[200]} ${base.color.translucent.standard[1000]}`,
          }}
        />
      </Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          gap: "0px",
          mt: "base.spacing.x10",
          position: "relative",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {walletAddress && (
          <>
            <Button
              onClick={onCopyWalletAddress}
              size="small"
              variant="tertiary"
            >
              <Button.Icon icon="Wallet" iconVariant={"bold"} />
              {toSimpleAddressFormat(walletAddress)}
            </Button>
            <CopyWalletModal
              isCopyWalletModalVisible={isCopyWalletModalVisible}
              setCopyWalletModalVisible={setCopyWalletModalVisible}
            />
          </>
        )}

        {rewardsProfileExperienceEnabled ? (
          <Stack
            sx={{
              px: "base.spacing.x6",
              mt: "base.spacing.x3",
              pt: "base.spacing.x3",
              width: "100%",
              height: "100%",
            }}
          >
            <StatusLevel onClick={() => openOverlay("status")} />
          </Stack>
        ) : null}

        <Stack
          direction="row"
          sx={{
            my: "base.spacing.x3",
            px: "base.spacing.x6",
            py: "base.spacing.x3",
            width: "100%",
          }}
        >
          <MenuItem
            emphasized
            size={menuItemSize}
            rc={<Link href="/balance" onClick={closeDrawer} />}
          >
            <MenuItem.FramedIcon icon="Coins" variant="bold" />
            <MenuItem.Label>Balance</MenuItem.Label>
          </MenuItem>
          <MenuItem
            emphasized
            size={menuItemSize}
            rc={<Link href="/inventory" onClick={closeDrawer} />}
          >
            <MenuItem.FramedIcon
              icon="TreasureChest"
              variant="bold"
              emphasized={false}
            />
            <MenuItem.Label>Inventory</MenuItem.Label>
          </MenuItem>
        </Stack>

        <Stack
          sx={{
            px: "base.spacing.x6",
            width: "100%",
            height: "100%",
          }}
        >
          {weeklyRewardsEligibilityEnabled && (
            <MenuItem
              emphasized
              size={menuItemSize}
              onClick={() => openOverlay("connections")}
              sx={{ width: "100%" }}
            >
              <MenuItem.FramedIcon icon="SocialShare" variant="bold" />
              <MenuItem.Label>Social Connections</MenuItem.Label>
            </MenuItem>
          )}

          {gemEarningHistoryEnabled && (
            <MenuItem
              emphasized
              size={menuItemSize}
              rc={<Link href="/gem-history" onClick={closeDrawer} />}
              sx={{ width: "100%" }}
            >
              <MenuItem.FramedIcon icon="ImxRewards" />
              <MenuItem.Label>Gem History</MenuItem.Label>
            </MenuItem>
          )}

          <MenuItem
            emphasized
            size={menuItemSize}
            rc={<Link href="/external-wallets" onClick={closeDrawer} />}
            sx={{ width: "100%" }}
          >
            <MenuItem.FramedIcon icon="WalletConnect" />
            <MenuItem.Label>External Wallets</MenuItem.Label>
          </MenuItem>
          <MenuItem
            emphasized
            size={menuItemSize}
            onClick={() => {
              logout();
              closeDrawer();
              setLoggingOut(true);
            }}
            sx={{ width: "100%" }}
          >
            <MenuItem.FramedIcon icon="Logout" variant="bold" />
            <MenuItem.Label>Log out</MenuItem.Label>
          </MenuItem>
        </Stack>
      </Stack>

      <LegalLinks
        containerSx={{
          px: "base.spacing.x6",
          alignSelf: "flex-start",
          position: "absolute",
          bottom: "base.spacing.x6",
        }}
      />
    </>
  );
}
