import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";

export type BooleanFlag =
  | "testFlag"
  | "engagementFeaturedQuestThemedBg"
  | "engagementDedicatedQuestDetailPage"
  | "engagementTakeoverEasterEgg"
  | "engagementGemfinity"
  | "playRqPromoBanner";

type BooleanFlags = Record<BooleanFlag, boolean | undefined>;

// Flag values are undefined until they've been loaded
export type Flags = BooleanFlags;

export function useFlag<T extends keyof Flags>(flag: T): Flags[T] {
  const flags = useLDFlags<Flags>();
  return flags[flag];
}
