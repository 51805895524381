import { useAccessTokenState } from "@/hooks/useAccessToken";
import { notifyError } from "@/utils/monitoring";
import { authorizedPost } from "@/utils/request";
import { useCallback } from "react";

type PhoneNumberOTPRequest = {
  phone_number: string;
};

type PhoneNumberOTPVerificationRequest = {
  phone_number: string;
  code: string;
};

// API error type
type ApiError = Error & {
  response?: {
    data: unknown;
  };
  status: number;
};

// Simplified error type for phone OTP operations
export type PhoneOtpError = {
  message: string;
  status?: number;
  code?: string;
};

/**
 * Hook for sending a phone OTP code
 * @returns Functions to send OTP to a phone number
 */
export function useSendPhoneOtp() {
  const accessToken = useAccessTokenState();

  const sendOtp = useCallback(
    async (
      phoneNumber: string,
    ): Promise<{ phone_number: string; error?: PhoneOtpError }> => {
      try {
        if (!accessToken) {
          throw new Error("No access token available");
        }

        const data: PhoneNumberOTPRequest = {
          phone_number: phoneNumber,
        };

        const res = await authorizedPost<{ phone_number: string }>(
          "/passport-profile/v1/phone-otp",
          data,
          accessToken,
        );

        return { phone_number: res.phone_number };
      } catch (error: unknown) {
        notifyError(error, "appError", { context: "sendPhoneOTP" });
        const apiError = error as ApiError;
        // Handle specific error responses
        if (apiError.response?.data) {
          const errorData = apiError.response.data as PhoneOtpError;
          return {
            phone_number: phoneNumber,
            error: {
              message:
                errorData.message || "An error occurred while sending OTP",
              status: apiError?.status,
              code: errorData?.code,
            },
          };
        }

        // Default error
        return {
          phone_number: phoneNumber,
          error: {
            message: "An unexpected error occurred",
          },
        };
      }
    },
    [accessToken],
  );

  return {
    sendOtp,
  };
}

/**
 * Hook for verifying a phone OTP code
 * @returns Functions to verify an OTP code
 */
export function useVerifyPhoneOtp() {
  const accessToken = useAccessTokenState();

  const verifyOtp = useCallback(
    async (
      phoneNumber: string,
      code: string,
    ): Promise<{ success: boolean; error?: PhoneOtpError }> => {
      try {
        if (!accessToken) {
          throw new Error("No access token available");
        }

        const data: PhoneNumberOTPVerificationRequest = {
          phone_number: phoneNumber,
          code,
        };

        await authorizedPost(
          "/passport-profile/v1/phone-otp/verify",
          data,
          accessToken,
        );

        return { success: true };
      } catch (error: unknown) {
        notifyError(error, "appError", { context: "verifyPhoneOTP" });
        const apiError = error as ApiError;

        // Handle specific error responses
        if (apiError.response?.data) {
          const errorData = apiError.response.data as PhoneOtpError;

          return {
            success: false,
            error: {
              message:
                errorData.message || "An error occurred while verifying OTP",
              status: apiError?.status,
            },
          };
        }

        // Default error
        return {
          success: false,
          error: {
            message: "An unexpected error occurred",
          },
        };
      }
    },
    [accessToken],
  );

  return {
    verifyOtp,
  };
}

/**
 * Combined hook for phone OTP operations
 * @returns Functions for sending and verifying OTP codes
 */
export function usePhoneOtp() {
  const { sendOtp } = useSendPhoneOtp();
  const { verifyOtp } = useVerifyPhoneOtp();

  return {
    sendOtp,
    verifyOtp,
  };
}
