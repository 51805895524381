/**
 * FBAN - Facebook App Native
 * FBAV - Facebook App Version
 * FBBV - Facebook Build Version
 * FBDV - Facebook Device
 * FBMD - Facebook Mobile Device
 * FBSN - Facebook System Name
 * FBSV - Facebook System Version
 * FBSS - Facebook Screen Scale
 * FBCR - Facebook Carrier
 * FBID - Facebook ID
 * FBLC - Facebook Locale
 * FBOP - Facebook Operations
 * FB_IAB - Facebook In-App Browser
 */

const REDIRECT_PATH_LOCAL_STORAGE_KEY = "post_login_redirect_path";
const REDIRECT_ACTION_LOCAL_STORAGE_KEY = "post_login_redirect_action";

export type RedirectAction = "login" | "follow";
export type RedirectData = {
  path: string;
  action: RedirectAction;
};

export function inFacebookBrowser(): boolean {
  const hasFacebookUserAgent =
    /FBAN|FBAV|FBBV|FBDV|FBMD|FBSN|FBSV|FBSS|FBCR|FBID|FBLC|FBOP|FB_IAB|Instagram/.test(
      window.navigator.userAgent,
    );
  return hasFacebookUserAgent;
}

/**
 * Redirects to the external browser if the user is in the ad browser and on the game details page
 * @param followOnSuccess - Whether to follow the game on login success
 * @returns - Whether the redirect was successful
 */
export function redirectGameToExternalBrowser(
  followOnSuccess: boolean,
): boolean {
  const isGameDetailsPage = /^\/games\/[^/]+(?:\/)?$/i.test(
    window.location.pathname,
  );
  const isInAdBrowser = inFacebookBrowser();
  if (!isInAdBrowser || !isGameDetailsPage) {
    return false;
  }

  const params = new URLSearchParams({
    path: window.location.pathname,
    follow: followOnSuccess.toString(),
    sessionId:
      localStorage.getItem("ajs_anonymous_id")?.replace(/"/g, "") || "",
  });
  const url = `${window.location.origin}/ab-landing?${params.toString()}`;
  if (/iphone|ipad|ipod|ios/i.test(window.navigator.userAgent.toLowerCase())) {
    window.location.href = `x-safari-${url}`;
    return true;
  }

  if (/android/i.test(window.navigator.userAgent)) {
    window.location.href = `intent:${url}#Intent;end`;
    return true;
  }
  return false;
}

export function storeRedirectData(path: string, followOnSuccess: boolean) {
  localStorage.setItem(REDIRECT_PATH_LOCAL_STORAGE_KEY, path);
  localStorage.setItem(
    REDIRECT_ACTION_LOCAL_STORAGE_KEY,
    followOnSuccess ? "follow" : "login",
  );
}

export function getRedirectData(): RedirectData | null {
  const path = localStorage.getItem(REDIRECT_PATH_LOCAL_STORAGE_KEY);
  const action = localStorage.getItem(REDIRECT_ACTION_LOCAL_STORAGE_KEY);
  if (path) {
    const redirectData: RedirectData = {
      path,
      action: (action as RedirectAction) ?? "login",
    };
    clearRedirectData();
    return redirectData;
  }
  return null;
}

function clearRedirectData() {
  localStorage.removeItem(REDIRECT_PATH_LOCAL_STORAGE_KEY);
  localStorage.removeItem(REDIRECT_ACTION_LOCAL_STORAGE_KEY);
}
