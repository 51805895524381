import { appConfig } from "@/constants";
import { useAccessTokenState } from "@/hooks";
import { fetcher } from "@/utils/fetch";
import { authorizedGet } from "@/utils/request";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import useSWR, { useSWRConfig, type SWRConfiguration } from "swr";
import useSWRImmutable from "swr/immutable";
import {
  calculateProgressPercentage,
  findCurrentLevel,
  findCurrentStatus,
  findNextLevel,
  findNextStatus,
} from "./utils";

export interface RewardsProfileResponse {
  xp: number;
  level: number;
  /** Maps to ProgressionStatus.label */
  status: string;
}

export interface RewardsProgress {
  status: {
    current: ProgressionStatus;
    next: ProgressionStatus;
    percentage: number;
  };
  level: {
    current: ProgressionLevel;
    next: ProgressionLevel;
    percentage: number;
  };
}

// Shared dev profile state across all hook instances
const devProfileState: RewardsProfileResponse = {
  xp: 0,
  level: 1,
  status: "Status A",
};

export interface RewardsProgressionResponse {
  levels: ProgressionLevel[];
  statuses: ProgressionStatus[];
}

export interface ProgressionLevel {
  id: number;
  requirements: {
    xp: number;
    quests?: string[];
  };
}

export interface ProgressionStatus {
  id: number;
  icon: string;
  /** Maps to RewardsProfileResponse.status */
  label: string;
  description: string;
  requirements: { xp: number; quests?: string[] };
}

const baseUrl = appConfig.IMMUTABLE_BASE_URI;

const config: SWRConfiguration = {
  revalidateOnFocus: true,
};

export function useRewardsProgression() {
  const { rewardsProfileExperienceEnabled } = useFlags();
  const {
    data: rewardsProgression,
    error: rewardsProgressionError,
    isLoading: rewardsProgressionIsLoading,
    mutate: rewardsProgressionMutate,
  } = useSWRImmutable<RewardsProgressionResponse>(
    rewardsProfileExperienceEnabled && `${baseUrl}/v1/rewards/progression`,
    fetcher,
  );

  return {
    rewardsProgression,
    rewardsProgressionError,
    rewardsProgressionIsLoading,
    rewardsProgressionMutate,
  };
}

export function useRewardsProfileApi() {
  const accessToken = useAccessTokenState();
  const { rewardsProfileExperienceEnabled, rewardsStatusDevPanelEnabled } =
    useFlags();

  const { cache } = useSWRConfig();
  const mutatedState = cache.get("/v1/rewards/profile");

  const {
    data: rewardsProfile,
    error: rewardsProfileError,
    isLoading: rewardsProfileIsLoading,
    mutate: rewardsProfileMutate,
  } = useSWR<RewardsProfileResponse>(
    rewardsProfileExperienceEnabled && accessToken
      ? ["/v1/rewards/profile", accessToken]
      : null,
    async ([path, token]: [string, string]) => {
      if (rewardsStatusDevPanelEnabled) {
        if (mutatedState) {
          Object.assign(devProfileState, mutatedState.data);
          return mutatedState.data;
        }
        return devProfileState;
      }
      return authorizedGet(path, token);
    },
    {
      ...config,
    },
  );

  return {
    rewardsProfile,
    rewardsProfileIsLoading,
    rewardsProfileError,
    rewardsProfileMutate,
  };
}

export function useRewardsProfile() {
  const {
    rewardsProfile: rewardsProfileResponse,
    rewardsProfileIsLoading,
    rewardsProfileError,
    rewardsProfileMutate,
  } = useRewardsProfileApi();

  const {
    rewardsProgression,
    rewardsProgressionIsLoading,
    rewardsProgressionError,
    rewardsProgressionMutate,
  } = useRewardsProgression();

  const isLoading = rewardsProfileIsLoading || rewardsProgressionIsLoading;
  const error = rewardsProfileError || rewardsProgressionError;

  const rewardsProgress: RewardsProgress | undefined = useMemo(() => {
    if (!rewardsProfileResponse || !rewardsProgression || error) {
      return undefined;
    }

    const currentStatus = findCurrentStatus(
      rewardsProfileResponse,
      rewardsProgression,
    );
    const currentLevel = findCurrentLevel(
      rewardsProfileResponse,
      rewardsProgression,
    );

    if (!currentStatus || !currentLevel) {
      return undefined;
    }

    const nextStatus = findNextStatus(rewardsProgression, currentStatus);
    const nextLevel = findNextLevel(
      rewardsProgression,
      rewardsProfileResponse.level,
    );

    return {
      status: {
        current: currentStatus,
        next: nextStatus,
        percentage: calculateProgressPercentage(
          currentStatus,
          nextStatus,
          rewardsProfileResponse,
        ),
      },
      level: {
        current: currentLevel,
        next: nextLevel,
        percentage: calculateProgressPercentage(
          currentLevel,
          nextLevel,
          rewardsProfileResponse,
        ),
      },
    };
  }, [rewardsProfileResponse, rewardsProgression, error]);

  return {
    profile: rewardsProfileResponse,
    progress: rewardsProgress,
    rewardsProgression,
    isLoading,
    error,
    mutate: {
      profile: rewardsProfileMutate,
      progression: rewardsProgressionMutate,
    },
  };
}
