import { appConfig } from "@/constants";

export class FetcherError extends Error {
  public status?: number;
  public info?: unknown;

  constructor(info?: unknown, status?: number, req?: RequestInfo) {
    let message: string;
    if (typeof req === "string") {
      message = `Error with request at endpoint: ${req}`;
    } else if (req instanceof Request) {
      message = `Error with request at endpoint: ${req.url}`;
    } else {
      message = "Error with request";
    }

    super(message);

    this.status = status;
    this.info = info;

    this.name = "FetcherError";
  }
}

export async function fetcher<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const response = await fetch(input, {
    headers: {
      "Content-Type": "application/json",
    },
    ...init,
  });

  if (!response.ok) {
    const error = new FetcherError(
      await response.json(),
      response.status,
      input,
    );
    throw error;
  }
  return response.json();
}

export const apiFetcher = async (path: string) => {
  const res = await fetch(appConfig.IMMUTABLE_BASE_URI + path);
  return await res.json();
};
