import { useRewardsProfile } from "@/hooks/";
import { base } from "@biom3/design-tokens";
import { Box, ButtCon, FramedIcon } from "@biom3/react";
import { animate } from "motion";
import { useMemo } from "react";
import { useEffect, useRef } from "react";
interface CircularBorderProgressProps {
  progress: number;
  size: number;
  borderWidth?: number;
}

function CircularBorderProgress({
  progress,
  size,
  borderWidth = 2,
  children,
}: CircularBorderProgressProps & { children?: React.ReactNode }) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    animate(ref.current, { "--progress-percent": progress }, { duration: 0.5 });
  }, [progress]);

  return (
    <Box
      domRef={ref}
      style={
        {
          "--progress-percent": 0,
        } as React.CSSProperties
      }
      sx={{
        position: "relative",
        w: `${size}px`,
        aspectRatio: 1,
        background: `conic-gradient(#E98267, #DA6748 calc(var(--progress-percent) * 1%), ${base.color.translucent.inverse[600]} 0%)`,
        borderRadius: "50%",
        display: "grid",
        placeItems: "center",
        zIndex: 0,
        "& > *": {
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: `${borderWidth}px`,
          left: `${borderWidth}px`,
          right: `${borderWidth}px`,
          bottom: `${borderWidth}px`,
          borderRadius: "50%",
          bg: "base.color.neutral.700",
          zIndex: 0,
        }}
      />
      {children}
    </Box>
  );
}

export function StatusHeaderPoc({ onClick }: { onClick: () => void }) {
  const { profile, progress } = useRewardsProfile();

  const icon = useMemo(() => {
    switch (profile?.status) {
      case "Status A":
        return "Sword";
      case "Status B":
        return "Shield";
      case "Status C":
        return "TreasureChest";
      default:
        return "Sword";
    }
  }, [profile?.status]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "base.spacing.x4",
        pr: "base.spacing.x4",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <CircularBorderProgress
          progress={progress?.level.percentage || 0}
          size={54}
        >
          <ButtCon
            onClick={onClick}
            icon="Profile"
            iconVariant="bold"
            variant="primary"
            sx={{
              maxw: "base.icon.size.600",
              bg: "base.color.neutral.700",
            }}
          />
          <FramedIcon
            icon={icon}
            size="xSmall"
            circularFrame
            sx={{
              position: "absolute",
              top: "-4px",
              right: "-4px",
              background: "linear-gradient(135deg, #E98267, #DA6748)",
              border: "1px solid",
              borderColor: "base.color.neutral.700",
            }}
          />
        </CircularBorderProgress>
      </Box>
    </Box>
  );
}
